import { User } from "./user";

export enum AttachmentType {
  RECORDING = "recording",
  PRESENTATION = "presentation",
  REPOSITORY = "repository",
  OTHER = "other",
}

export type Attachment = {
  id: number;
  created_by: User | null;
  name: string;
  description?: string;
  url: string;
  type: AttachmentType;
  created_at: string;
  updated_at: string;
};
