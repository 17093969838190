import { Box, Container, Skeleton } from "@mui/material";
import { useMemo, useState } from "react";
import { Navigate } from "react-router-dom";

import { isApiError, useGetEventDetailsQuery, useGetUserQuery } from "../../api";
import { Attachment } from "../../components/Attachment";
import { AttachmentFormModal } from "../../components/AttachmentFormModal";
import { AttachmentModal } from "../../components/AttachmentModal";
import { ErrorMessage } from "../../components/ErrorMessage/errorMessage";
import { ParticipantsList } from "../../components/ParticipantsList/participantsList";
import { ROUTES } from "../../config";
import { useRequiredParams } from "../../hooks";
import { MainCard, TagsList } from "./components";

const attachmentsSkeleton = (amount: number) => {
  return [...Array(amount)].map((_, i) => (
    <Skeleton key={i} height="34px" width={150} variant="rectangular" style={{ borderRadius: 8 }} />
  ));
};

export const EventDetails = () => {
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const { eventId, eventType } = useRequiredParams<{ eventId: string; eventType: string }>();

  const {
    data: eventDetails,
    isLoading,
    error,
  } = useGetEventDetailsQuery({
    id: eventId,
    type: eventType,
  });
  const { data: user } = useGetUserQuery();

  const participating = useMemo(() => {
    if (!eventDetails || !user) return false;

    return eventDetails.participants.some((participant) => participant.email === user.email);
  }, [eventDetails, user]);

  const isMine = useMemo(() => {
    if (!eventDetails || !user) return false;

    return eventDetails.created_by?.id === user.id;
  }, [eventDetails, user]);

  const attachments = eventDetails?.attachments.map((attachment) => (
    <Attachment key={attachment.id} attachment={attachment} />
  ));

  if (isApiError(error) && error.status === 404) {
    return <Navigate to={ROUTES.notFound} />;
  }

  return (
    <Container
      disableGutters
      sx={{
        pt: "20px",
        pb: "20px",
        pr: { xs: "30px", lg: "0px" },
        pl: { xs: "30px", lg: "0px" },
      }}
    >
      {isMine && eventDetails && (
        <AttachmentModal
          isOpen={isAttachmentModalOpen}
          onRequestClose={() => setIsAttachmentModalOpen(false)}
        >
          <AttachmentFormModal
            onClose={() => setIsAttachmentModalOpen(false)}
            eventId={eventDetails.id}
          />
        </AttachmentModal>
      )}

      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: { xs: "column", md: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              paddingLeft: "0px",
              paddingRight: "0px",
              width: "100%",
            }}
          >
            <Box>
              {!eventDetails || isLoading ? (
                <Box sx={{ height: "478px" }}>
                  <Skeleton height="100%" variant="rectangular" style={{ borderRadius: 8 }} />
                </Box>
              ) : (
                <MainCard
                  eventDetails={eventDetails}
                  eventId={eventId}
                  participating={participating}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  mt: "20px",
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                      mb: "20px",
                      maxWidth: "100%",
                    }}
                  >
                    {attachmentsSkeleton(2)}
                  </Box>
                ) : (
                  attachments
                )}
              </Box>
              <TagsList
                description={eventDetails?.description}
                list={eventDetails?.tags}
                isLoading={isLoading}
              />
            </Box>
          </Box>
          <ParticipantsList list={eventDetails?.participants} isLoading={isLoading} />
        </Box>
      </Box>
      {isApiError(error) && <ErrorMessage message={error.data.detail} />}
    </Container>
  );
};
